<template lang="html">
<v-container fluid>
  <v-row class="mb-5">
    <v-col>
      <v-toolbar flat color="transparent">
        <v-spacer></v-spacer>
        <div sytle="width:100px" class="d-flex">
            <v-select
            hide-details
            outlined
            :items="['Bawahan Saya']"
            label="kategori"
            class="mr-3"
            ></v-select>
            <v-select
            hide-details
            outlined
            v-model="month"
            :full-width="false"
            :items='months'
            label="bulan"
            >
          </v-select>
        </div>
      </v-toolbar>
    </v-col>
  </v-row>
  <v-row>
    <v-col v-for="(peg,i) in pegawai" :key="i">
      <base-material-card
        class="v-card-profile"
        avatar="https://picsum.photos/id/1027/200"
        max-width="400"
      >
        <v-card-text class="text-center">
          <h4 class="display-2 mb-1 orange--text text--darken-2">
            {{peg.nama}}
          </h4>
          <h4 class="font-weight-light grey--text">
            NIP. {{peg.nip}}{{i}}
          </h4>
          <h4 class="display-1 mb-3 green--text">
            {{peg.jabatan}}
          </h4>

          <v-chip
            class="ma-2"
            color="primary"
            text-color="white"
            @click="dialog=true"
          >
            Jumlah SKP
            <v-avatar
              right
              class="accent darken-2"
            >
              0
            </v-avatar>
          </v-chip>
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-row>

  <v-dialog v-model="dialog">
    <v-card>
      <crud
        :crudSettings="crudSettings"
        :headers="headers"
        :responseData="{data:items}"
        @onTableChange="updateTable"
      >
        <template v-slot:prepend-header>
          <v-list two-line height="65px">
            <v-list-item>
               <v-list-item-avatar>
                 <v-img src="https://picsum.photos/id/1027/200"></v-img>
               </v-list-item-avatar>
               <v-list-item-content>
                 <v-list-item-title class="overline orange--text text--darken-2" style="font-size:12px">Anne Lorem</v-list-item-title>
                 <v-list-item-subtitle style="font-size:12px">Analisis Kinerja</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template v-slot:append-actions>
          <v-icon title="lapor ulang" @click="laporUlang()">
            mdi-pencil-box
          </v-icon>
        </template>
      </crud>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog=false">Tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import Crud from '@/components/common/Crud'
export default {
  components:{
    Crud,
  },

  data:function(){
    return{
      dialog:false,
      month:'November',
      months:['triwulan 1','triwulan 2','triwulan 3','triwulan 4','tahun'],

      headers:[
        {text:'No' ,value:'no'},
        {text:'Sasaran Kinerja Pegawai', value:'skp'},
        {text:'Indikator', value:'indikator'},
        {text:'Target', value:'aktivitas'},
        {text:'Realisasi', value:'realisasi'},
        {text:'Status', value:'status'},
      ],
      items:[],

      pegawai:[
        {jabatan: 'Analisis Kinerja', nama:'Kasep Banget', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Kasep Hijau', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Buset Banget', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Kacep Banget', nip:'111100011110000'},
        {jabatan: 'Analisis Kinerja', nama:'Kadep Bongat', nip:'111100011110000'},
      ],
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        deleteTitleKey:'peg_nama',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
    }
  },
  methods:{
    updateTable(val){},
  }
}
</script>

<style lang="css" scoped>
</style>
